import React, { memo, useEffect, useState } from 'react';

import { Menu, Modal } from 'antd';

import Img from "@components/atoms/Img";
import WFMenuItem from '@components/Menu';

import Navigator from '@navigator/index';

import { LockedIcon } from '@assets/images';
import { Cross } from '@assets/index';
import { IAttributes } from '@common/domain/models/AttributeGroups';
import { fetchAttributeGroups, getPassions } from '@common/redux/careerAdvisor/actions';
import { getAttributesGroup, getPassionsData, getPassionsLoader } from '@common/redux/careerAdvisor/selectors';
import { PrimaryButton } from '@components/Button';
import { WFImg } from '@components/index';
import Text, { FontFamilyVariant, TextType } from '@components/Text';
import { careerAdvisorMessages } from '@containers/CareerAdvisor/messages';
import { IAppMenuItem } from '@layout/Dashboard';
import { isQuestionnareCompleted } from '@presentation/CareerAdvisor/utils';
import { translate } from '@translations/Translater';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import './style.less';

interface IProps {
  menuItems: IAppMenuItem[];
  className?: string;
  onMenuChange: (key: string, index?: number) => void;
  selectedKey: string;
  attributesGroup: IAttributes[]
  fetchAttributeGroups: () => void;
  getPassions: () => void;
  passionsData: any;
  getPassionsLoader: boolean;
}

function DashBoardMenu(props: IProps) {
  const { t } = useTranslation()
  const { selectedKey, menuItems, onMenuChange, attributesGroup, fetchAttributeGroups, passionsData, getPassions, getPassionsLoader } = props;
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(false)
  }
  const renderImage = (iconImage) => {
    switch (iconImage) {
      case 'LibraryIcon':
        return null
      default:
        break;
    }
  };

  useEffect(() => {
    if (!attributesGroup.length) {
      fetchAttributeGroups();
    }
  }, [attributesGroup]);

  useEffect(() => {
    if (!passionsData) {
      getPassions()
    }
  }, [passionsData])

  return (
    <div className="dashboard_menu_container">
      <Menu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={[menuItems[0].key]}
        selectedKeys={[selectedKey]}
      >
        {menuItems.map((menuItem: IAppMenuItem) => {
          const isTabLocked =
            menuItem?.locked === true &&
            (!isQuestionnareCompleted(attributesGroup) || passionsData == null);
          const onClick = () => {
            if (isTabLocked) {
              setOpenModal(true);
            } else {
              const route = menuItem.url;
              Navigator.push(route);
              onMenuChange(menuItem.key);
            }
          };

          return (
            <WFMenuItem key={menuItem.key} onClick={onClick}>
              <div className="vertical_menu" id={`${menuItem.name.toString().split(" ")[0]}`}>
                {menuItem.iconImage && (
                  <Img alt="menu icon" src={renderImage(menuItem.iconImage)} className="icon_image" />
                )}
                <div className={`menu-container ${isTabLocked ? 'typo-menu-name' : ''}`}>
                  {/* Show locked icon if the tab is locked */}
                  {isTabLocked && <WFImg src={LockedIcon} alt="Locked Icon" />}
                  {menuItem.name}
                </div>
              </div>
            </WFMenuItem>
          );
        })}
      </Menu>
      <Modal
        open={openModal}
        className="modal-container"
        footer={false}
        closable={false}
        onCancel={toggleModal}
        centered
      >
        <div className='modal-parent'>
          <div className='title-container'>
            <Text
              textType={TextType.subHeading1}
              fontFamilyVariant={FontFamilyVariant.semibold}
              text={translate(t, careerAdvisorMessages.lockedShareReportTitle)}
              className="title"
            />
            <WFImg src={Cross} alt='Cross Icon' className='cross-image' onClick={toggleModal} />
          </div>
          <Text
            textType={TextType.h4}
            fontFamilyVariant={FontFamilyVariant.regular}
            text={translate(t, careerAdvisorMessages.lockedShareReportDescription)}
            className="desc"
          />
          <PrimaryButton htmlType='button' className="close-button" onClick={toggleModal}>{translate(t, careerAdvisorMessages.close)}</PrimaryButton>
        </div>

      </Modal>
    </div>

  );
}

const mapStateToProps = (state: any) => ({
  attributesGroup: getAttributesGroup(state),
  passionsData: getPassionsData(state),
  getPassionsLoader: getPassionsLoader(state)
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchAttributeGroups: () => dispatch(fetchAttributeGroups()),
  getPassions: () => dispatch(getPassions()),
});

export default memo(connect(
  mapStateToProps, mapDispatchToProps
)(DashBoardMenu));
