import { Achievement, Aptitudes, Artistic, Compensation, Conventional, CreativeThinkingInnovation, Enterprising, EntrepreneurialSkills, Independence, Interests, InterpersonalSkills, Investigative, LogicalReasoningAnalyticalSkills, NumericalAptitudes, OrganisationalSkill, PhysicalAndManualSkills, Realistic, Recognition, Security, Social, SpatialAwareness, SupportiveEnvironment, TechnicalProficiency, Values, VerbalAbilityAndCommunication, WorkLifeBalance } from "@assets/images";
import { i18n } from "@translations/i18n";


export const resposeType = {
    TEXT: "text",
    LIST: "list",
    PROFILE: "profile",
    ORDERED_LIST: "orderedList"
};

export const availabilityType = {
    high: "high",
    medium: "medium",
    low: "low to none",
    lowHi: "कम या नहीं"
};

export const dropdownValue= {
    all: 'All'
}

export const collegePlanOptions = [
    {
        label: i18n.t('careerAdvisor.labelsAndTitles.PLAN_FOR_HIGHER_STUDIES'),
        value: "College",
    },
    {
        label: i18n.t('careerAdvisor.labelsAndTitles.NO_PLAN_FOR_HIGHER_STUDIES'),
        value: "Non-College",
    },
    {
        label: i18n.t('careerAdvisor.labelsAndTitles.NOT_SURE_HIGHER_STUDIES'),
        value: "College,Non-College",
    },
];

export const iconImages = {
    aptitude: Aptitudes,
    logicalReasoningAndAnalyticalSkills: LogicalReasoningAnalyticalSkills,
    verbalAbilityAndCommunicationSkills: VerbalAbilityAndCommunication,
    numericalAptitude: NumericalAptitudes,
    creativeThinkingAndInnovation: CreativeThinkingInnovation,
    spatialAwareness: SpatialAwareness,
    interpersonalSkills: InterpersonalSkills,
    technicalProficiency: TechnicalProficiency,
    organizationalSkills: OrganisationalSkill,
    entrepreneurialSkills: EntrepreneurialSkills,
    physicalAndManualSkills: PhysicalAndManualSkills,
    interest: Interests,
    realistic: Realistic,
    investigative: Investigative,
    artistic: Artistic,
    social : Social,
    enterprising: Enterprising,
    conventional: Conventional,
    values: Values,
    workLifeBalance: WorkLifeBalance,
    achievement: Achievement,
    independence: Independence,
    recognition: Recognition,
    supportiveEnvironment: SupportiveEnvironment,
    compensation: Compensation,
    security: Security

}



export const attributeStatus = {
  REVIEW: 'Review',
  START: 'Start',
  RESUME: 'Resume',
  RETAKE: 'Retake'
};

export const SelectionType = {
    Questionnaire: 'questionnaire',
    Slider: 'slider'
}

export const topAttributesCount = {
    aptitude: 4,
    interest: 3,
    values: 3
};

export const ratingLevel = {
    HIGH: 'high',
    LOW: 'low',
    EQUAL: 'Equal'
};

