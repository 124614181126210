import { CrossIcon } from '@assets/images';
import { WFDefaultButton, WFImg, WFTextNormal } from '@components/index';
import { SearchTextInput } from '@components/Input';
import WFModal from '@components/molecules/Modal';
import Text, { FontFamilyVariant, TextType } from '@components/Text';
import { mentorMessages } from '@containers/Mentor/message';
import { translate } from '@translations/Translater';
import { useMediaQuery } from '@utils/CustomHooks';
import { Checkbox } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    closeModal: () => void;
    openModal: boolean;
    modalHeading?: string;
    isSearchable?: boolean;
    onSearch?: (payload: any) => void;
    searchTerm?: string;
    option?: any[];
    selectedItem?: any[];
    onSelectItem?: (event: any, item: any) => void;
    emptySearchMessage?: string;
    onCrossClick: (payload: any) => void;
    onApplyClick: () => void;
    heading?: string;
    cancel?: string;
    save?: string;
    modalPosition?: any;
    modalHeadingClassName?: any;
    isRemoving?: boolean;
    cross?: boolean
}

const FilterModal = (props: IProps) => {
    const { closeModal, openModal, modalHeading, isSearchable = false, onSearch, searchTerm, option, selectedItem, onSelectItem, emptySearchMessage, onCrossClick, onApplyClick, heading, cancel, save, modalPosition, modalHeadingClassName, isRemoving = false, cross = true } = props;
    const { t } = useTranslation()
    const isMobileView = useMediaQuery("(max-width: 767px)");
    const className = modalPosition?.top ? '' : 'multiSelect__modal'
    return <WFModal
        closable={false}
        destroyOnClose
        onCancel={closeModal}
        showModal={openModal}
        wrapClassName={className}
        style={{
            top: `${modalPosition?.top}px`,
            left: `${isMobileView ? 16 : modalPosition?.left}px`,
            position: 'absolute',
        }}
        maskStyle={{ background: 'none' }}
    >
        <div className="multiSelect__modal__container">
            <div className="multiSelect__header_container">
                <WFTextNormal
                    className={`multiSelect__title`}
                >{modalHeading}
                </WFTextNormal>
                {cross && <WFImg src={CrossIcon} onClick={closeModal} className="mob-reset-btn" />}
            </div>
            {isSearchable && <SearchTextInput
                className="multiSelect__search-input-field"
                onSearchKeywordChange={onSearch}
                placeholder={`${translate(t, mentorMessages.search)} ${heading}`}
                searchKeyword={searchTerm}
                // @ts-ignore
                delay={0}
                charLength={0}
            />}

            {option?.length > 0 ? <div className="multiSelect__scroll">
                {option?.map((item) => {
                    if (item) {
                        return (
                            <div className='option-item'>
                                <Checkbox
                                    onChange={(event) => onSelectItem(event, item)}
                                    checked={selectedItem?.includes(item?.value)}
                                    className='option-checkBox'
                                    value={item?.value}
                                    name={item?.name}
                                >

                                    <Text
                                        className="option-text"
                                        fontFamilyVariant={FontFamilyVariant.regular}
                                        text={item?.name}
                                        textType={TextType.paragraph2}
                                    />
                                </Checkbox>
                            </div>
                        )
                    }
                })}
            </div> : <Text className='not-found' text={emptySearchMessage || translate(t, mentorMessages.notFound)} fontFamilyVariant={FontFamilyVariant.regular} textType={TextType.paragraph2} />}
            <div className='button-section'>
                <WFDefaultButton htmlType={'button'} onClick={onCrossClick} className="reset-btn" >
                    {cancel}
                </WFDefaultButton>
                <WFDefaultButton htmlType={'button'}
                    disabled={(selectedItem.length === 0 && isRemoving == false) ? true : false}
                    onClick={onApplyClick} className={selectedItem?.length > 0 ? "apply-btn active-apply-btn" : `apply-btn ${isRemoving && 'active-apply-btn'}`} >
                    {save}
                </WFDefaultButton>
            </div>
        </div>
    </WFModal>
}

export default FilterModal;