import React from 'react';
import { getEmbedVideoId } from '@utils/DataMappingUtils';

interface IProps {
  videoUrl: string;
  height?: string;
  className?: string;
  title?: string;
}

export default function (props: IProps) {
    const { videoUrl, height = '300px', className, title } = props;
    const style = ['embed-responsive embed-responsive-21by9', className].join(
        ' '
    );
    return (
        <div className={style}>
            <iframe
                allowFullScreen
                className="embed-responsive-item"
                frameBorder="0"
                height={height}
                id="ytplayer"
                src={getEmbedVideoId(videoUrl)}
                title={title}
                width="100%"
            />
        </div>
    );
}
