import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { getLoggedInUserId } from "@utils/UserUtils";
import { NavigationUrl, URLs } from "@navigator/NavigationUrl";

interface IProps extends RouteProps {
  component: React.ComponentType<any>;
  isAuthenticated: boolean;
  exact?: boolean;
  to?: string;
  path: string | string[];
  location: any;
}

export class RouteAuthenticatedWithoutMenu extends React.Component<IProps, any> {
  private defaultRedirectRoute = NavigationUrl.generate(URLs.home); // Default redirect to home

  private renderRedirect = (to: string) => {
    return <Redirect to={{ pathname: to }} />;
  };

  render() {
    const {
      component: Component,
      isAuthenticated,
      to = this.defaultRedirectRoute,
      ...rest
    } = this.props;

    // Check authentication and user ID
    if (isAuthenticated && getLoggedInUserId()) {
      return (
        <Route
          {...rest}
          render={(props) => <Component {...props} />} // Render the component directly
        />
      );
    } else {
      return <Route {...rest} render={() => this.renderRedirect(to)} />;
    }
  }
}
