export const enum userRoles {
  student = 'STUDENT',
  faculty = 'FACULTY',
  regionalManager = 'REGIONAL_MANAGER',
  instituteAdmin = 'INSTITUTE_ADMIN',
  expert = "EXPERT"
};

export const FEATURE_NAME = {
  FRESHDESK: "freshdesk",
};

export const supportedFrontEndLanguageCodes = ['en', 'es', 'pt', 'en-US', 'hi'];
