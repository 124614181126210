import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal } from "antd";
import { getLoggedInUserId, makeUpdateProfileApiCall } from '@utils/UserUtils';
import { ProfileModel } from '@common/domain/models/Profile';
import { getProfileMap } from '@common/redux/profile/selectors';
import { fetchProductToursSelector } from '@common/redux/common/selectors';
import { IState } from '@store/webInterfaces';
import { PRODUCT_TOUR_STATUS, PRODUCT_TOURS } from '@constants/config';
import { welcome_intro_01, welcome_intro_02, welcome_intro_03, welcome_intro_04, welcome_intro_05 } from '@assets/index';
import { WFImg } from '@components/index';
import Text, { FontFamilyVariant, TextType } from '@components/Text';
import PrimaryButton from '@components/atoms/Button/PrimaryButton';
import './styles.less';
import { i18n } from '@translations/i18n';

interface IProps {
    profileMap?: any;
    loggedInUserId?: string;
    activeProducTours?: string[];
}

const WelcomeModal = (props: IProps) => {

    const {
        profileMap,
        loggedInUserId,
        activeProducTours
    } = props;

    const [openModal, setOpenModal] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0);

    const { t } = useTranslation();

    const profile: ProfileModel = profileMap?.[profileMap?.[loggedInUserId]];


    const IsTourCompleted = profile?.isTourCompleted(PRODUCT_TOURS.WELCOME_CAREER_ADVISOR) &&
        activeProducTours && activeProducTours?.includes(PRODUCT_TOURS.WELCOME_CAREER_ADVISOR);

    useEffect(() => {
        if (profile && !IsTourCompleted) {
            setOpenModal(true);
            setCurrentIndex(0);
        }
    }, [profile, activeProducTours])

    const toggleModal = () => {
        setOpenModal(!openModal);
        if (!openModal) { 
            setCurrentIndex(0);
        }
    }

    const onClickNext = () => {
        if (currentIndex !== Data.length - 1) {
            setCurrentIndex(currentIndex + 1)
        } else if (currentIndex === Data.length - 1) {
            setOpenModal(!openModal)
            makeUpdateProfileApiCall(profile, PRODUCT_TOURS.WELCOME_CAREER_ADVISOR, PRODUCT_TOUR_STATUS.completed);
        }
    }

    const onClickDot = (dotIndex) => {
        setCurrentIndex(dotIndex)
    }

    const Data = [{
        image: welcome_intro_01,
        heading: i18n.t("careerAdvisor.labelsAndTitles.COMPLETE_SELF_REVIEW"),
        description: i18n.t("careerAdvisor.labelsAndTitles.COMPLETE_SELF_REVIEW_DESC")
    },
    {
        image: welcome_intro_02,
        heading: i18n.t("careerAdvisor.labelsAndTitles.START_YOUR_QUESTIONNAIRES"),
        description: i18n.t("careerAdvisor.labelsAndTitles.START_YOUR_QUESTIONNAIRES_DESC")
    },
    {
        image: welcome_intro_03,
        heading: i18n.t("careerAdvisor.labelsAndTitles.EXPLORE_MATCHED_ROLES"),
        description: i18n.t("careerAdvisor.labelsAndTitles.EXPLORE_MATCHED_ROLES_DESC")
    },
    {
        image: welcome_intro_04,
        heading: i18n.t("careerAdvisor.labelsAndTitles.EXPLORE_JOB_ROLES"),
        description: i18n.t("careerAdvisor.labelsAndTitles.EXPLORE_JOB_ROLES_DESC")
    },
    {
        image: welcome_intro_05,
        heading: i18n.t("careerAdvisor.labelsAndTitles.SAVE_YOUR_FAVORITES"),
        description: i18n.t("careerAdvisor.labelsAndTitles.SAVE_YOUR_FAVORITES_DESC")
    }
    ];

    const visibleData = Data[currentIndex];

    if (IsTourCompleted) {
        return null
    }

    return (
        <>
            {

                <Modal
                    open={openModal}
                    className="profile-completion-modal-container"
                    footer={false}
                    closable={false}
                    onCancel={toggleModal}
                    centered
                >
                    <WFImg src={visibleData.image} className='welcome_image' />
                    <div className='content'>
                        <Text
                            fontFamilyVariant={FontFamilyVariant.bold}
                            text={visibleData.heading}
                            textType={TextType.h4}
                            className='heading_text'
                        />
                        <Text
                            fontFamilyVariant={FontFamilyVariant.regular1}
                            text={visibleData.description}
                            textType={TextType.h8}
                            className='description_text'
                        />
                        <PrimaryButton
                            type={'primary'}
                            htmlType="button"
                            onClick={onClickNext}
                            className="welcome_next_btn"
                        >
                            {currentIndex === Data.length - 1 ? i18n.t("careerAdvisor.labelsAndTitles.START_YOUR_JOURNEY") : i18n.t("register.next")}
                        </PrimaryButton>
                    </div>
                    <div className='dot_container'>
                        {Data.map((item, index) => {
                            return <div onClick={() => onClickDot(index)} className={`welcome_dot ${index === currentIndex && "welcome_dot_active"}`}></div>
                        })}
                    </div>
                </Modal>
            }
        </>
    );

}


export const mapStateToProps = (state: IState) => {
    return {
        loggedInUserId: getLoggedInUserId(),
        profileMap: getProfileMap(state),
        activeProducTours: fetchProductToursSelector(state),
    };

};

export const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WelcomeModal);
