import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


// import { ISetFilterData } from '@common/domain/models/Mentor';

import { CloseOutlined, DownOutlined } from '@assets/antdIcons';
import { translate } from '@translations/Translater';

import { WFDefaultButton } from '@components/index';

import { mentorMessages } from '@containers/Mentor/message';

import FilterModal from './components';
import './styles.less';
import { i18n } from '@translations/i18n';

interface IProps {
    isSearchable?: boolean;
    heading?: string;
    onOkButtonText?: string;
    onCancleButtonText?: string;
    option?: {
        name: string
        value: string,
    }[];
    onOkClick?: (payload: any) => void;
    onCancleClick?: () => void;
    onSearch?: (e: any) => void;
    searchTerm?: string;
    defaultItem?: string[];
    emptySearchMessage?: string;
    modalHeading?: string;
    filterName?: string;
    filterDataMap: { [heading: string]: string },
    isFavouritePage?: boolean
    openModalParent?: boolean
}
const MultiSelectDropDown = (props: IProps) => {
    const { t } = useTranslation();
    const { heading, option, isSearchable, onSearch, filterDataMap, filterName, searchTerm, onOkClick, defaultItem, emptySearchMessage, modalHeading, isFavouritePage, openModalParent } = props
    const [openModal, setOpenModal] = useState(false || openModalParent);
    const [selectedItem, setSelectedItem] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [count, setCount] = useState("");
    const [selectedCountry, setSelectedCountry] = useState([]);

    useEffect(function onFilterApply() {
        if (Object.keys(filterDataMap).length == 0) {
            setSelectedItem([]);
            setCount("")
        }
    }, [Object.keys(filterDataMap).length])

    useEffect(function onMount() {
        if (defaultItem?.length >= 0) {
            setSelectedItem(defaultItem);
        };
    }, [defaultItem]);
    const closeModal = () => {
        setOpenModal(false);
    };
    const showModal = () => {
        setOpenModal(true);
    };
    const onCrossClick = (event: any) => {
        event.stopPropagation();
        setSelectedItem([]);
        setCount("")
        if (heading === translate(t, mentorMessages.location)) {
            setSelectedCity([]);
            setSelectedCountry([]);
            const payload = {
                heading: filterName
            }
            onOkClick(payload);
        }
        const payload = {
            heading: filterName
        }
        onOkClick(payload);
    }
    const onSelectCityItem = (event: any, item: any) => {
        const cityValues = [];
        if (event.target.checked && !item?.isCountry) {
            cityValues.push(...selectedCity, event.target.value);
        } else if (!event.target.checked && !item?.isCountry) {
            cityValues.push(...selectedCity.filter((item) => item !== event.target.value))
        }
        setSelectedCity(cityValues);
    };
    const onSelectCountryItem = (event: any, item: any) => {
        const countryValues = [];
        if (event.target.checked) {
            countryValues.push(...selectedCountry, event.target.value);
        } else if (!event.target.checked) {
            countryValues.push(...selectedCountry.filter((item) => item !== event.target.value))
        }
        setSelectedCountry(countryValues);
    };
    const onSelectItem = (event: any, item: any) => {
        if (heading === translate(t, mentorMessages.location)) {
            if (item?.isCountry) {
                onSelectCountryItem(event, item)
            } else if (!item?.isCountry) {
                onSelectCityItem(event, item)
            }
        }
        const values = [];
        if (event.target.checked) {
            values.push(...selectedItem, event.target.value);
        } else if (!event.target.checked) {
            values.push(...selectedItem.filter((item) => item !== event.target.value));
        }
        setSelectedItem(values);
    };
    const onApplyClick = () => {
        setOpenModal(false);
        setCount(selectedItem?.length > 0 ? `(${selectedItem?.length})` : "")
        if (heading === translate(t, mentorMessages.location)) {
            const cityPayload = {
                heading: "cities",
                data: selectedCity.toString()
            };
            const countryPayload = {
                heading: "countries",
                data: selectedCountry.toString()
            };
            onOkClick(cityPayload);
            onOkClick(countryPayload);
        } else {
            const payload = {
                heading: filterName,
                data: !isFavouritePage ? selectedItem?.toString() : selectedItem
            };
            onOkClick(payload);
        }
    };

    return (
        <div className='multiSelect__container'>
            <WFDefaultButton htmlType={'button'} onClick={showModal} className={(selectedItem?.length || openModal) ? "multiSelect-btn multiSelect-btn-open" : "multiSelect-btn"} >
                {`${heading}${" "} ${!isFavouritePage ? count : ''}`}
                {!isFavouritePage && (selectedItem?.length > 0 ? <CloseOutlined onClick={onCrossClick} /> : <DownOutlined />)}
            </WFDefaultButton>
            <FilterModal
                closeModal={closeModal}
                openModal={openModal}
                modalHeading={modalHeading}
                isSearchable={isSearchable}
                onSearch={onSearch}
                searchTerm={searchTerm}
                option={option}
                selectedItem={selectedItem}
                onSelectItem={onSelectItem}
                emptySearchMessage={emptySearchMessage}
                onCrossClick={onCrossClick}
                onApplyClick={onApplyClick}
                heading={heading}
                cancel={i18n.t('global.labelsAndTitles.RESET')}
                save={translate(t, mentorMessages.apply)}
            />
        </div>
    );
};

export default MultiSelectDropDown;


