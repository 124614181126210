import { JobProfileModel } from "@common/domain/models/JobProfileModel";

import { AttributeGroups } from "@common/domain/models/AttributeGroups";
import { iconImages } from "@presentation/CareerAdvisor/constant";
import { careerAdvisorActions, IPassionPayloadModel } from "./actions";

interface IFilterValues {
    sector: string;
    subSector: string;
    collegeCategory: string;
}
export interface ICareerAdvisorState {
    careerPreference: string[];
    jobRoles: JobProfileModel[];
    attributesGroup: AttributeGroups[];
    jobDetail: JobProfileModel;
    totalJobs: Number;
    getFavouriteKeys: string[];
    filterValues: IFilterValues;
    attributesGroupByKey: any;
    submitAttributeUserResponse: any;
    deleteAttributeUserResponse: AttributeGroups[];
    getQuestionnaireResponse: any;
    pssciveCourses: any;
    pssciveCoursesCount: number;
    jobRolesById: any;
    getJobProfileByUserId: JobProfileModel[];
    getFavoriteJobProfileByUserId: JobProfileModel[];
    getAttributesResponseByUserId: AttributeGroups[];
    getUserPreferenceByUserId: string[];
    getUserDetailsByUserId: string;
    getFavoriteJobRolesByUserId: any;
    getPassions: IPassionPayloadModel[];
    getPassionsByUserId: IPassionPayloadModel[];
    jobProfiles: JobProfileModel[];
    passionJobProfilesByUserId: JobProfileModel[];
    loaders: {
        fetchJobRoles: boolean;
        fetchJobDetail: boolean;
        addOrRemoveFavourites: boolean;
        getFavouriteKeys: boolean;
        updateCareerPreference: boolean;
        fetchCareerPreference: boolean;
        attributesGroup: boolean;
        submitAttributeUserResponse: boolean;
        deleteAttributeUserResponse: boolean;
        getQuestionnaireResponse: boolean;
        pssciveCourses: boolean;
        getJobProfileByUserId: boolean;
        getAttributesResponseByUserId: boolean;
        getUserPreferenceByUserId: boolean;
        getFavoriteJobRolesByUserId: boolean;
        getUserDetailsByUserId: boolean;
        getPassions: boolean;
        jobProfiles: boolean;
        getPassionsByUserId: boolean;
        passionJobProfilesByUserId: boolean;
    };
    errors: {
        fetchJobRoles: string;
        fetchJobDetail: string;
        addOrRemoveFavourites: string;
        getFavouriteKeys: string;
        updateCareerPreference: string;
        fetchCareerPreference: string;
        attributesGroup: string;
        submitAttributeUserResponse: string;
        deleteAttributeUserResponse: string;
        getQuestionnaireResponse: string;
        pssciveCourses: string;
        getJobProfileByUserId: string;
        getAttributesResponseByUserId: string;
        getUserPreferenceByUserId: string;
        getFavoriteJobRolesByUserId: string;
        getUserDetailsByUserId: string;
        getPassions: string;
        jobProfiles: string;
        getPassionsByUserId: string;
        passionJobProfilesByUserId: string;
    };

}

export const initialState: ICareerAdvisorState = {
    careerPreference: [],
    attributesGroup: [],
    jobRoles: [],
    jobDetail: {} as JobProfileModel,
    totalJobs: 0,
    getFavouriteKeys: [],
    filterValues: {
        sector: null,
        subSector: null,
        collegeCategory: null
    },
    attributesGroupByKey: {},
    submitAttributeUserResponse: {},
    deleteAttributeUserResponse: [],
    getQuestionnaireResponse: [],
    pssciveCourses: [],
    pssciveCoursesCount: 0,
    jobRolesById: {},
    getJobProfileByUserId: [],
    getFavoriteJobProfileByUserId: [],
    getAttributesResponseByUserId: [],
    getUserPreferenceByUserId: [],
    getUserDetailsByUserId: "",
    getFavoriteJobRolesByUserId: [],
    getPassions: null,
    getPassionsByUserId: null,
    jobProfiles: [],
    passionJobProfilesByUserId: [],
    loaders: {
        fetchJobRoles: false,
        fetchJobDetail: false,
        addOrRemoveFavourites: false,
        getFavouriteKeys: false,
        updateCareerPreference: false,
        fetchCareerPreference: false,
        attributesGroup: false,
        submitAttributeUserResponse: false,
        deleteAttributeUserResponse: false,
        getQuestionnaireResponse: false,
        pssciveCourses: false,
        getJobProfileByUserId: false,
        getAttributesResponseByUserId: false,
        getUserPreferenceByUserId: false,
        getFavoriteJobRolesByUserId: false,
        getUserDetailsByUserId: false,
        getPassions: false,
        jobProfiles: false,
        getPassionsByUserId: false,
        passionJobProfilesByUserId: false,
    },
    errors: {
        fetchJobRoles: "",
        fetchJobDetail: "",
        addOrRemoveFavourites: "",
        getFavouriteKeys: "",
        updateCareerPreference: "",
        fetchCareerPreference: "",
        attributesGroup: "",
        submitAttributeUserResponse: "",
        deleteAttributeUserResponse: "",
        getQuestionnaireResponse: "",
        pssciveCourses: "",
        getJobProfileByUserId: "",
        getAttributesResponseByUserId: "",
        getUserPreferenceByUserId: "",
        getFavoriteJobRolesByUserId: "",
        getUserDetailsByUserId: "",
        getPassions: "",
        jobProfiles: "",
        getPassionsByUserId: "",
        passionJobProfilesByUserId: ""
    },
};



export const careerAdvisorReducer = (
    state: ICareerAdvisorState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {

        case careerAdvisorActions.FETCH_JOB_ROLES: {
            return {
                ...state,
                loaders: { ...state.loaders, fetchJobRoles: true },
                totalJobs: 0,
                errors: { ...state.errors, fetchJobRoles: "" },
            };
        }

        case careerAdvisorActions.FETCH_JOB_ROLES_SUCCESS: {
            const { page, total, jobProfiles } = action.payload;
            updateJobRolesIds(jobProfiles, state.jobRolesById);
            return {
                ...state,
                jobRoles: page === 1 ? [...jobProfiles] : [...state.jobRoles, ...jobProfiles],
                totalJobs: total,
                loaders: { ...state.loaders, fetchJobRoles: false },
            };
        }

        case careerAdvisorActions.FETCH_JOB_ROLES_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, fetchJobRoles: false },
                errors: { ...state.errors, fetchJobRoles: action.payload },
            };

        case careerAdvisorActions.FETCH_JOBROLE_BY_ID:
            return {
                ...state,
                loaders: { ...state.loaders, fetchJobDetail: true },
                errors: { ...state.errors, fetchJobDetail: "" },
            };

        case careerAdvisorActions.FETCH_JOBROLE_BY_ID_SUCCESS: {
            const { response, isInPassion, isInQuestionnare } = action.payload
            updateJobRoleDetails(response, isInPassion, isInQuestionnare)
            return {
                ...state,
                jobDetail: response,
                loaders: { ...state.loaders, fetchJobDetail: false },
            };
        }

        case careerAdvisorActions.FETCH_JOBROLE_BY_ID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, fetchJobDetail: false },
                errors: { ...state.errors, fetchJobDetail: action.payload },
            };

        case careerAdvisorActions.FETCH_ATTRIBUTE_GROUPS:
            return {
                ...state,
                loaders: { ...state.loaders, attributesGroup: true },
                errors: { ...state.errors, attributesGroup: "" },
            };

        case careerAdvisorActions.FETCH_ATTRIBUTE_GROUPS_SUCCESS:
            updateAttributeGroups(action.payload, state.attributesGroupByKey);
            return {
                ...state,
                attributesGroup: action.payload,
                loaders: { ...state.loaders, attributesGroup: false },
            };

        case careerAdvisorActions.FETCH_ATTRIBUTE_GROUPS_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, attributesGroup: false },
                errors: { ...state.errors, attributesGroup: action.payload },
            };

        case careerAdvisorActions.ADD_OR_REMOVE_FAVOURITE: {
            return {
                ...state,
                loaders: { ...state.loaders, addOrRemoveFavourites: true },
                errors: { ...state.errors, addOrRemoveFavourites: "" },
            };
        }

        case careerAdvisorActions.ADD_OR_REMOVE_FAVOURITE_SUCCESS: {
            const { jobRoleKeys } = action.payload;
            const { jobDetail } = state;
            const filteredJobRoles = [...updateJobRoles(jobRoleKeys, state.jobRoles)];
            const filterPassionRoles = updatePassionRoles(jobRoleKeys, state.jobProfiles)
            const isIdExistInJobDetail = jobRoleKeys.includes(jobDetail?.getJobRoleKey());
            jobDetail['isFavourited'] = isIdExistInJobDetail;
            return {
                ...state,
                jobRoles: filteredJobRoles,
                jobProfiles: filterPassionRoles,
                getFavouriteKeys: jobRoleKeys,
                jobDetail,
                loaders: { ...state.loaders, addOrRemoveFavourites: false },
            };
        }

        case careerAdvisorActions.ADD_OR_REMOVE_FAVOURITE_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, addOrRemoveFavourites: false },
                errors: { ...state.errors, addOrRemoveFavourites: action.payload },
            };
        case careerAdvisorActions.GET_FAVOURITE_KEYS: {
            return {
                ...state,
                loaders: { ...state.loaders, getFavouriteKeys: true },
                errors: { ...state.errors, getFavouriteKeys: "" },
            };
        }

        case careerAdvisorActions.GET_FAVOURITE_KEYS_SUCCESS: {
            return {
                ...state,
                getFavouriteKeys: action.payload,
                loaders: { ...state.loaders, getFavouriteKeys: false },
            };
        }

        case careerAdvisorActions.GET_FAVOURITE_KEYS_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getFavouriteKeys: false },
                errors: { ...state.errors, getFavouriteKeys: action.payload },
            };
        case careerAdvisorActions.UPDATE_CAREER_PREFERENCE:
            return {
                ...state,
                loaders: { ...state.loaders, updateCareerPreference: true },
                errors: { ...state.errors, updateCareerPreference: "" },
            };

        case careerAdvisorActions.UPDATE_CAREER_PREFERENCE_SUCCESS:
            return {
                ...state,
                careerPreference: action.payload,
                loaders: { ...state.loaders, updateCareerPreference: false },
            };

        case careerAdvisorActions.UPDATE_CAREER_PREFERENCE_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, updateCareerPreference: false },
                errors: { ...state.errors, updateCareerPreference: action.payload },
            };

        case careerAdvisorActions.FETCH_CAREER_PREFERENCE:
            return {
                ...state,
                loaders: { ...state.loaders, fetchCareerPreference: true },
                errors: { ...state.errors, fetchCareerPreference: "" },
            };

        case careerAdvisorActions.FETCH_CAREER_PREFERENCE_SUCCESS:
            return {
                ...state,
                careerPreference: action.payload,
                loaders: { ...state.loaders, fetchCareerPreference: false },
            };

        case careerAdvisorActions.FETCH_CAREER_PREFERENCE_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, fetchCareerPreference: false },
                errors: { ...state.errors, fetchCareerPreference: action.payload },
            };

        case careerAdvisorActions.FETCH_JOBROLE_BY_ID_SUCCESS:
            return {
                ...state,
                jobDetail: action.payload,
                loaders: { ...state.loaders, fetchJobDetail: false },
            };

        case careerAdvisorActions.FETCH_JOBROLE_BY_ID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, fetchJobDetail: false },
                errors: { ...state.errors, fetchJobDetail: action.payload },
            };

        case careerAdvisorActions.FETCH_ATTRIBUTE_GROUPS:
            return {
                ...state,
                loaders: { ...state.loaders, attributesGroup: true },
                errors: { ...state.errors, attributesGroup: "" },
            };

        case careerAdvisorActions.SUBMIT_ATTRIBUTE_USER_RESPONSE:
            return {
                ...state,
                loaders: { ...state.loaders, submitAttributeUserResponse: true },
                errors: { ...state.errors, submitAttributeUserResponse: "" },
            };

        case careerAdvisorActions.SUBMIT_ATTRIBUTE_USER_RESPONSE_SUCCESS:
            return {
                ...state,
                submitAttributeUserResponse: action.payload,
                loaders: { ...state.loaders, submitAttributeUserResponse: false },
            };

        case careerAdvisorActions.SUBMIT_ATTRIBUTE_USER_RESPONSE_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, submitAttributeUserResponse: false },
                errors: { ...state.errors, submitAttributeUserResponse: action.payload },
            };

        case careerAdvisorActions.UPDATE_FILTER_VALUES: {
            return {
                ...state,
                filterValues: {
                    ...action.payload
                },
            };
        };

        case careerAdvisorActions.DELETE_ATTRIBUTE_USER_RESPONSE:
            return {
                ...state,
                loaders: { ...state.loaders, deleteAttributeUserResponse: true },
                errors: { ...state.errors, deleteAttributeUserResponse: "" },
            };

        case careerAdvisorActions.DELETE_ATTRIBUTE_USER_RESPONSE_SUCCESS:
            return {
                ...state,
                deleteAttributeUserResponse: action.payload,
                loaders: { ...state.loaders, deleteAttributeUserResponse: false },
            };

        case careerAdvisorActions.DELETE_ATTRIBUTE_USER_RESPONSE_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, deleteAttributeUserResponse: false },
                errors: { ...state.errors, deleteAttributeUserResponse: action.payload },
            };

        case careerAdvisorActions.GET_QUESTIONNAIRE_RESPONSE:
            return {
                ...state,
                loaders: { ...state.loaders, getQuestionnaireResponse: true },
                errors: { ...state.errors, getQuestionnaireResponse: "" },
            };

        case careerAdvisorActions.GET_QUESTIONNAIRE_RESPONSE_SUCCESS:
            return {
                ...state,
                getQuestionnaireResponse: action.payload,
                loaders: { ...state.loaders, getQuestionnaireResponse: false },
            };

        case careerAdvisorActions.GET_QUESTIONNAIRE_RESPONSE_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getQuestionnaireResponse: false },
                errors: { ...state.errors, getQuestionnaireResponse: action.payload },
            };

        case careerAdvisorActions.GET_PSSCIVE_COURSES: {
            return {
                ...state,
                loaders: { ...state.loaders, pssciveCourses: true },
                totalJobs: 0,
                errors: { ...state.errors, fetchJobRoles: "" },
            };
        }

        case careerAdvisorActions.GET_PSSCIVE_COURSES_SUCCESS: {
            const { page, total, pssciveCourses } = action.payload;
            return {
                ...state,
                pssciveCourses: page === 1 ? [...pssciveCourses] : [...state.pssciveCourses, ...pssciveCourses],
                pssciveCoursesCount: total,
                loaders: { ...state.loaders, pssciveCourses: false },
            };
        }

        case careerAdvisorActions.GET_PSSCIVE_COURSES_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, pssciveCourses: false },
                errors: { ...state.errors, pssciveCourses: action.payload },
            };
        case careerAdvisorActions.GET_JOB_PROFILE_BY_USERID:
            return {
                ...state,
                loaders: { ...state.loaders, getJobProfileByUserId: true },
                errors: { ...state.errors, getJobProfileByUserId: "" },
            };

        case careerAdvisorActions.GET_JOB_PROFILE_BY_USERID_SUCCESS:
            const { favourite, jobProfiles } = action.payload;
            return {
                ...state,
                ...(favourite && { getFavoriteJobProfileByUserId: jobProfiles }),
                ...(!favourite && { getJobProfileByUserId: jobProfiles }),
                loaders: { ...state.loaders, getJobProfileByUserId: false },
            };

        case careerAdvisorActions.GET_JOB_PROFILE_BY_USERID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getJobProfileByUserId: false },
                errors: { ...state.errors, getJobProfileByUserId: action.payload },
            };

        case careerAdvisorActions.GET_FAVORITED_JOB_PROFILE_BY_USERID:
            return {
                ...state,
                loaders: { ...state.loaders, getJobProfileByUserId: true },
                errors: { ...state.errors, getJobProfileByUserId: "" },
            };

        case careerAdvisorActions.GET_FAVORITED_JOB_PROFILE_BY_USERID_SUCCESS: {
            const { favourite, jobProfiles } = action.payload;
            return {
                ...state,
                ...(favourite && { getFavoriteJobProfileByUserId: jobProfiles }),
                ...(!favourite && { getJobProfileByUserId: jobProfiles }),
                loaders: { ...state.loaders, getJobProfileByUserId: false },
            };
        }

        case careerAdvisorActions.GET_FAVORITED_JOB_PROFILE_BY_USERID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getJobProfileByUserId: false },
                errors: { ...state.errors, getJobProfileByUserId: action.payload },
            };

        case careerAdvisorActions.GET_ATTRIBUTES_RESPONSES_BY_USERID:
            return {
                ...state,
                loaders: { ...state.loaders, getAttributesResponseByUserId: true },
                errors: { ...state.errors, getAttributesResponseByUserId: "" },
            };

        case careerAdvisorActions.GET_ATTRIBUTES_RESPONSES_BY_USERID_SUCCESS:
            updateAttributeGroupsImages(action.payload);
            return {
                ...state,
                getAttributesResponseByUserId: action.payload,
                loaders: { ...state.loaders, getAttributesResponseByUserId: false },
            };

        case careerAdvisorActions.GET_ATTRIBUTES_RESPONSES_BY_USERID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getAttributesResponseByUserId: false },
                errors: { ...state.errors, getAttributesResponseByUserId: action.payload },
            };

        case careerAdvisorActions.GET_USER_PREFERENCES_BY_USERID:
            return {
                ...state,
                loaders: { ...state.loaders, getUserPreferenceByUserId: true },
                errors: { ...state.errors, getUserPreferenceByUserId: "" },
            };

        case careerAdvisorActions.GET_USER_PREFERENCES_BY_USERID_SUCCESS:
            return {
                ...state,
                getUserPreferenceByUserId: action.payload,
                loaders: { ...state.loaders, getUserPreferenceByUserId: false },
            };

        case careerAdvisorActions.GET_USER_PREFERENCES_BY_USERID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getUserPreferenceByUserId: false },
                errors: { ...state.errors, getUserPreferenceByUserId: action.payload },
            };

        case careerAdvisorActions.GET_FAVORITE_JOBROLES_BY_USERID:
            return {
                ...state,
                loaders: { ...state.loaders, getFavoriteJobRolesByUserId: true },
                errors: { ...state.errors, getFavoriteJobRolesByUserId: "" },
            };

        case careerAdvisorActions.GET_FAVORITE_JOBROLES_BY_USERID_SUCCESS:
            return {
                ...state,
                getFavoriteJobRolesByUserId: action.payload,
                loaders: { ...state.loaders, getFavoriteJobRolesByUserId: false },
            };

        case careerAdvisorActions.GET_FAVORITE_JOBROLES_BY_USERID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getFavoriteJobRolesByUserId: false },
                errors: { ...state.errors, getFavoriteJobRolesByUserId: action.payload },
            };

        case careerAdvisorActions.GET_USER_DETAILS_BY_USERID:
            return {
                ...state,
                loaders: { ...state.loaders, getUserDetailsByUserId: true },
                errors: { ...state.errors, getUserDetailsByUserId: "" },
            };

        case careerAdvisorActions.GET_USER_DETAILS_BY_USERID_SUCCESS:
            return {
                ...state,
                getUserDetailsByUserId: action.payload,
                loaders: { ...state.loaders, getUserDetailsByUserId: false },
            };

        case careerAdvisorActions.GET_USER_DETAILS_BY_USERID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getUserDetailsByUserId: false },
                errors: { ...state.errors, getUserDetailsByUserId: action.payload },
            };
        case careerAdvisorActions.GET_PASSIONS:
            return {
                ...state,
                loaders: { ...state.loaders, getPassions: true },
                errors: { ...state.errors, getPassions: "" },
            };

        case careerAdvisorActions.GET_PASSIONS_SUCCESS:
            return {
                ...state,
                getPassions: action.payload,
                loaders: { ...state.loaders, getPassions: false },
            };

        case careerAdvisorActions.GET_PASSIONS_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getPassions: false },
                errors: { ...state.errors, getPassions: action.payload },
            };

        case careerAdvisorActions.GET_PASSIONS_BY_USERID:
            return {
                ...state,
                loaders: { ...state.loaders, getPassionsByUserId: true },
                errors: { ...state.errors, getPassionsByUserId: "" },
            };

        case careerAdvisorActions.GET_PASSIONS_BY_USERID_SUCCESS:
            return {
                ...state,
                getPassionsByUserId: action.payload,
                loaders: { ...state.loaders, getPassionsByUserId: false },
            };

        case careerAdvisorActions.GET_PASSIONS_BY_USERID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getPassionsByUserId: false },
                errors: { ...state.errors, getPassionsByUserId: action.payload },
            };

        case careerAdvisorActions.UPDATE_PASSIONS:
            return {
                ...state,
                loaders: { ...state.loaders, getPassions: true },
                errors: { ...state.errors, getPassions: "" },
            };

        case careerAdvisorActions.UPDATE_PASSIONS_SUCCESS:
            return {
                ...state,
                getPassions: action.payload,
                loaders: { ...state.loaders, getPassions: false },
            };

        case careerAdvisorActions.UPDATE_PASSIONS_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getPassions: false },
                errors: { ...state.errors, getPassions: action.payload },
            };
        case careerAdvisorActions.GET_JOBPROFILES:
            return {
                ...state,
                loaders: { ...state.loaders, jobProfiles: true },
                errors: { ...state.errors, jobProfiles: "" },
            };

        case careerAdvisorActions.GET_JOBPROFILES_SUCCESS:
            return {
                ...state,
                jobProfiles: action.payload,
                loaders: { ...state.loaders, jobProfiles: false },
            };

        case careerAdvisorActions.GET_JOBPROFILES_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, jobProfiles: false },
                errors: { ...state.errors, jobProfiles: action.payload },
            };

        case careerAdvisorActions.GET_PASSIONS_JOBPROFILES_BY_USERID:
            return {
                ...state,
                loaders: { ...state.loaders, passionJobProfilesByUserId: true },
                errors: { ...state.errors, passionJobProfilesByUserId: "" },
            };

        case careerAdvisorActions.GET_PASSIONS_JOBPROFILES_BY_USERID_SUCCESS:
            return {
                ...state,
                passionJobProfilesByUserId: action.payload,
                loaders: { ...state.loaders, passionJobProfilesByUserId: false },
            };

        case careerAdvisorActions.GET_PASSIONS_JOBPROFILES_BY_USERID_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, passionJobProfilesByUserId: false },
                errors: { ...state.errors, passionJobProfilesByUserId: action.payload },
            };
        default:
            return state;
    }

}

const updateJobRoles = (jobRoleKeys, jobRoles) => {
    jobRoles?.forEach(role => {
        if (jobRoleKeys.includes(role?.jobRoleKey)) {
            role.isFavourited = true;
        } else {
            role.isFavourited = false;
        }
    });
    return jobRoles;
};

const updatePassionRoles = (jobRolesKeys, passionObject) => {
    for (const key in passionObject) {
        if (Array?.isArray(passionObject[key])) {
            passionObject[key] = passionObject[key]?.map(item => {
                if (jobRolesKeys?.includes(item?.jobRoleKey)) {
                    item.isFavourited = true
                } else {
                    item.isFavourited = false;
                }
                return item;
            });
        }
    }
    return passionObject;
}

const updateAttributeGroupsImages = (attributeGroups) => {
    attributeGroups.forEach((attributeGroup) => {
        attributeGroup['mediaURL'] = iconImages[attributeGroup.attributeGroupKey];
        attributeGroup.attributeScores.forEach((attribute) => {
            attribute['mediaURL'] = iconImages[attribute.attributeKey];
        })
    });
};


const updateAttributeGroups = (attributeGroups, existingMap) => {
    attributeGroups.forEach((attributeGroup) => {
        attributeGroup['mediaURL'] = iconImages[attributeGroup.key];
        attributeGroup.attributes.forEach((attribute) => {
            attribute['mediaURL'] = iconImages[attribute.attributeKey];
        })
        existingMap[attributeGroup.key] = attributeGroup
    });
};

const updateJobRoleDetails = (jobRoleDetails, isInPassion, isInQuestionnare) => {
    jobRoleDetails.aptitudeRatings.forEach((eachRating) => {
        eachRating['mediaURL'] = iconImages[eachRating.attributeKey]
    })
    jobRoleDetails.interestRatings.forEach((eachRating) => {
        eachRating['mediaURL'] = iconImages[eachRating.attributeKey]
    })
    jobRoleDetails.valueRatings.forEach((eachRating) => {
        eachRating['mediaURL'] = iconImages[eachRating.attributeKey]
    })
    jobRoleDetails.isInPassion = isInPassion;
    jobRoleDetails.isInQuestionnare = isInQuestionnare
}

const updateJobRolesIds = (jobRoles, existingMap) => {
    jobRoles.forEach((eachRole) => {
        existingMap[eachRole.jobRoleKey] = eachRole
    });
};





