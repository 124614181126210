import { IApiClient } from "@common/network/interfaces";
import {
  deserializeJsonObj,
  deserializeJsonObjCollection,
} from "@utils/ObjectMapperUtil";

import { JobProfileModel } from "@common/domain/models/JobProfileModel";
import { APIv1, PublicAPIv1 } from "@common/network/constants";
import { ApiClient } from "@network/client";
import { AttributeGroupResponse } from "@models/AttributeGroupResponse";
import { AttributeGroups } from "@models/AttributeGroups";
import { PssciveCourse } from "@models/PssciveCourse";


const ENDPOINTS = {
  fetchBrowsedJobRoles: () => `${APIv1}/carreradvisoryservice/jobProfiles`,
  fetchJobProfileById: (jobProfileId: string) => `${APIv1}/carreradvisoryservice/jobProfiles/${jobProfileId}`,
  favourites: () => `${APIv1}/carreradvisoryservice/favouriteJobRole`,
  updateAndFetchCareerPreference: (userId: string) => `${APIv1}/carreradvisoryservice/users/${userId}/userCareerPreferences`,
  fetchAttributesGroup: () => `${APIv1}/carreradvisoryservice/attributeGroups`,
  attributesGroupResponse: () => `${APIv1}/carreradvisoryservice/attributeUserResponses`,
  deleteAttributeGroupResponse: (attributeKey) => `${APIv1}/carreradvisoryservice/attributeGroup/${attributeKey}/attributeUserResponses`,
  getQuestionnaireResponse: (questionnaireId, respondentId) => `${APIv1}/questionnaireservice/questionnaires/${questionnaireId}/${respondentId}/responses`,
  getPssciveCourses: () => `${APIv1}/carreradvisoryservice/PSSCIVECourses`,
  getJobProfileByUserId: (userId: string) => `${PublicAPIv1}/carreradvisoryservice/users/${userId}/jobProfiles`,
  getAttributeUserResponsesByUserId: (userId: string) => `${PublicAPIv1}/carreradvisoryservice/users/${userId}/attributeUserResponses`,
  getUserCareerPreferencesByUserId: (userId: string) => `${PublicAPIv1}/carreradvisoryservice/users/${userId}/userCareerPreferences`,
  getfavouriteJobRoleByUserId: (userId: string) => `${PublicAPIv1}/carreradvisoryservice/users/${userId}/favouriteJobRole`,
  getUserDataByUserId: () => `${PublicAPIv1}/profileservice/users`,
  getPassion: () => `${APIv1}/carreradvisoryservice/userPassions`,
  getJobProfile: () => `${APIv1}/carreradvisoryservice/jobProfiles`,
  getPassionByUserId: (userId: string) => `${PublicAPIv1}/carreradvisoryservice/users/${userId}/userPassions`
};


class CareerAdvisorRepository {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  public fetchBrowsedJobRoles = async (params: any): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchBrowsedJobRoles(), { ...params });
    const jobProfiles = deserializeJsonObjCollection(response.jobProfiles, JobProfileModel, 'fetchBrowsedJobRoles') as JobProfileModel[];
    return {
      jobProfiles,
      total: response.total
    }
  }

  public fetchJobProfileById = async (jobProfileId: string): Promise<JobProfileModel[]> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchJobProfileById(jobProfileId));
    return deserializeJsonObj(response, JobProfileModel, 'fetchJobProfileById') as JobProfileModel[];
  }

  public addOrRemoveFavourites = async (payload) => {
    const response = await this.apiClient.patch(ENDPOINTS.favourites(), payload);
    return response?.jobRoleKeys;
  }

  public getFavouriteKeys = async () => {
    const response = await this.apiClient.get(ENDPOINTS.favourites());
    return response;
  }

  public updateCareerPreference = async (payload): Promise<any> => {
    const { userId, bodyPayload, ...params } = payload;
    const response = await this.apiClient.patch(ENDPOINTS.updateAndFetchCareerPreference(userId), bodyPayload, params);
    return response.collegeCategory;
  }

  public fetchCareerPreference = async (userId: string): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.updateAndFetchCareerPreference(userId));
    return response.collegeCategory;
  }

  public fetchAttributesGroup = async (): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchAttributesGroup());
    const attributeValues = deserializeJsonObjCollection(response.attributeGroups, AttributeGroups, 'fetchAttributesGroup') as AttributeGroups[];
    return attributeValues;
  }

  public fetchAttributesGroupResponse = async (): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.attributesGroupResponse());
    const attributeValues = deserializeJsonObjCollection(response.attributeGroups, AttributeGroupResponse, 'fetchAttributesGroupResponse') as AttributeGroupResponse[];
    return attributeValues;
  }

  public submitAttributeUserResponse = async (payload): Promise<any> => {
    const response = await this.apiClient.patch(ENDPOINTS.attributesGroupResponse(), payload);
    return response.collegeCategory;
  }

  public deleteAttributeUserResponse = async (payload): Promise<any> => {
    const response = await this.apiClient.delete(ENDPOINTS.deleteAttributeGroupResponse(payload));
    return response?.attributeGroups;
  }

  public getQuestionnaireResponse = async (payload): Promise<any> => {
    const { questionnaireId, respondentId } = payload;
    const response = await this.apiClient.get(ENDPOINTS.getQuestionnaireResponse(questionnaireId, respondentId));
    return response?.responses || [];
  }

  public getPssciveCourses = async (params: any): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.getPssciveCourses(), { ...params });
    const PSSCIVECourses = deserializeJsonObjCollection(response.PSSCIVECourses, PssciveCourse, 'getPssciveCourses') as PssciveCourse[];
    return {
      PSSCIVECourses,
      total: response.total
    }
  }

  public getJobProfileByUserId = async (payload): Promise<any> => {
    const { userId, ...params } = payload
    const response = await this.apiClient.get(ENDPOINTS.getJobProfileByUserId(userId), params);
    const jobData = deserializeJsonObjCollection(response?.jobProfiles, JobProfileModel, 'getJobProfileByUserId') as JobProfileModel[]
    return {
      jobProfiles: jobData || [],
      favourite: params.favourite || false
    };
  }

  public getFavoritedJobProfileByUserId = async (payload): Promise<any> => {
    const { userId, ...params } = payload
    const response = await this.apiClient.get(ENDPOINTS.getJobProfileByUserId(userId), params);
    const jobData = deserializeJsonObjCollection(response?.jobProfiles, JobProfileModel, 'getFavoritedJobProfileByUserId') as JobProfileModel[]
    return {
      jobProfiles: jobData || [],
      favourite: params.favourite || false
    };
  }

  public getAttributesResponsesByUserId = async (userId: string): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.getAttributeUserResponsesByUserId(userId));
    return response?.attributeGroups || [];
  }

  public getUserPreferencesByUserId = async (userId: string): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.getUserCareerPreferencesByUserId(userId));
    return response?.collegeCategory || [];
  }

  public getFavoriteJobrolesByUserId = async (userId: string): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.getfavouriteJobRoleByUserId(userId));
    return response?.jobRoleKeys || [];
  }

  public getUserDataByUserId = async (params: any): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.getUserDataByUserId(), params);
    return response?.profile[0]?.firstName || "";
  }

  public getUserPassion = async (): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.getPassion())
    return response?.passionsInformation
  }

  public updateUserPassion = async (params) => {
    const response = await this.apiClient.patch(ENDPOINTS.getPassion(), params);
    return response?.passionsInformation;
  }

  public getJobProfile = async (params): Promise<JobProfileModel[]> => {
    const response = await this.apiClient.get(ENDPOINTS.getJobProfile(), params);
    return deserializeJsonObjCollection(response?.jobProfiles, JobProfileModel, 'getJobProfile') as JobProfileModel[];
  }

  public getPassionByUserId = async (userId: string): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.getPassionByUserId(userId))
    return response?.passionsInformation
  }

  public getPassionJobProfileByUserId  = async (payload): Promise<any> => {
    const { userId, ...params } = payload
    const response = await this.apiClient.get(ENDPOINTS.getJobProfileByUserId(userId), params);
    return deserializeJsonObjCollection(response?.jobProfiles, JobProfileModel, 'getPassionJobProfileByUserId') as JobProfileModel[];
  }

}


const careerAdvisorRepository = new CareerAdvisorRepository(ApiClient);

export { careerAdvisorRepository as CareerAdvisorRepository };
